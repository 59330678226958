import Button from "react-bootstrap/Button";
import TextTransition, { presets } from "react-text-transition";

import reactlogo from "./Favorites/react.png";
import nodejs from "./Favorites/njs.png";
import js from "./Favorites/js.png";
import mdb from "./Favorites/mdb.png";
import git from "./Favorites/git.png";
import ps from "./Favorites/ps.png";

const WorkInfo = (props) => {
  function scrollDown() {
    document.getElementById("bg-2").scrollTo({ behavior: "smooth", top: 0 });
  }

  const work_info = {
    2: {
      name: "NFT Portfolio",
      description:
        "This is a frontend design for a project that was created for users to have somewhere to display their NFT collection. The backend of this will be a venture into web3.",
      link: "https://hopeful-leavitt-277a45.netlify.app/",
    },
    1: {
      name: "Equity Pulse",
      description:
        "This application was developed for fetching company annual and quarterly financial data. The application makes an API call each time the user searches a symbol.",
      link: "https://angry-cray-48ca4f.netlify.app/",
    },
    3: {
      name: "Equity Pulse Monitor",
      description:
        "This application was developed with the purpose of fetching new data from an ever changing changing database and displaying it for users in realtime.",
      tech: "Built using , Express, React, NodeJS, Socket.io and Firebase",
      link: "https://equitypulse.herokuapp.com/",
    },
    4: {
      name: "Restaurant Picker",
      description:
        "This application was developed with the purpose of helping users pick a place to eat. Simply put in your location and let it decide where you should eat.",
      link: "https://laughing-pasteur-b32af8.netlify.app/",
    },
    0: {
      name: "ABC NFT",
      description:
        "This is a fully functional application for creators to interact with their audience via NFTs. This application is in it's infancy and I am working on a more complete version now.",
      link: "https://reverent-ardinghelli-2047fd.netlify.app/",
    },
  };

  return (
    <>
      <div id="work-info" className="work-info">
        <></>
        {props.workReady && (
          <div className="description">
            <span className="info-title">
              <TextTransition
                text={work_info[props.count].name}
                springConfig={{ stiffness: 50, damping: 20 }}
                noOverflow
              />
            </span>
            <div className="tech">
              <p className="tech-used">Built Using</p>
              <div className="technologies">
                <img className="up" src={reactlogo} alt="" />
              </div>
            </div>
            <span className="info-description">
              <TextTransition
                text={work_info[props.count].description}
                springConfig={{ stiffness: 50, damping: 20 }}
                noOverflow
              />
            </span>

            <div className="buttons-container">
              <Button
                href={work_info[props.count].link}
                className="button-links"
              >
                Demo
              </Button>
            </div>
          </div>
        )}

        {props.hireReady && (
          <>
            {" "}
            <section className="faq">
              <h3>FAQ</h3>

              <details
                className="faq__question"
                id="q1"
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <summary
                  className="faq__toggle"
                  itemprop="name"
                  aria-expanded="true"
                  aria-controls="faq_answer_2"
                >
                  WHAT IS THE PROCESS TO GET STARTED?
                </summary>

                <div
                  id="faq_answer_2"
                  className="faq__answer"
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                >
                  <p itemscope="text">
                    Send me a message in the contact box to the left it is the
                    quickest way to get in contact with me. Tell me what you're
                    looking to do, we'll iron out the details and i'll get
                    started on your project.
                  </p>
                </div>
              </details>

              <details
                className="faq__question"
                id="q2"
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <summary
                  className="faq__toggle"
                  itemprop="name"
                  aria-expanded="true"
                  aria-controls="faq_answer_2"
                >
                  WHAT ARE YOUR PAYMENT OPTIONS?
                </summary>

                <div
                  id="faq_answer_2"
                  className="faq__answer"
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                >
                  <p itemscope="text">
                    I accept Paypal, Stripe, Venmo, or Crypto. You can pay via
                    credit card with Paypal or Stripe.
                  </p>
                </div>
              </details>

              <details
                className="faq__question"
                id="q3"
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <summary
                  className="faq__toggle"
                  itemprop="name"
                  aria-expanded="true"
                  aria-controls="faq_answer_1"
                >
                  DO YOU CHARGE PER HOUR OR PER PROJECT?
                </summary>

                <div
                  id="faq_answer_1"
                  className="faq__answer"
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                >
                  <span className="reveal_overlay"></span>

                  <p itemscope="text">
                    Charging per project works better for you and me. You know
                    the price upfront and I can focus on creating something
                    great without counting the hours.
                  </p>
                </div>
              </details>

              <details
                className="faq__question"
                id="q4"
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <summary
                  className="faq__toggle"
                  itemprop="name"
                  aria-expanded="true"
                  aria-controls="faq_answer_2"
                >
                  HOW OFTEN WILL WE BE IN CONTACT?
                </summary>

                <div
                  id="faq_answer_2"
                  className="faq__answer"
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                >
                  <p itemscope="text">
                    I want to maintain a healthy relationship with you. If that
                    means a call every day, cool. If it means keeping in touch
                    through a weekly email exchange, that works. Whatever works
                    for you.
                  </p>
                </div>
              </details>

              <details
                className="faq__question"
                id="q5"
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <summary
                  className="faq__toggle"
                  itemprop="name"
                  aria-expanded="true"
                  aria-controls="faq_answer_3"
                >
                  YOU DIDN'T ANSWER MY QUESTION.
                </summary>

                <div
                  id="faq_answer_3"
                  className="faq__answer"
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                >
                  <p itemscope="text">
                    I'm always happy to have a chat and discuss what's on your
                    mind, so feel free to send me an email through the contact
                    form or contact me directly at colton@colton.com
                  </p>
                </div>
              </details>
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default WorkInfo;
