import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

const Contact = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  function loadingImg() {
    var loading_logo = document.getElementById("hire-bg");
    setTimeout(function () {
      loading_logo.style.opacity = 100;
    }, 1200);
  }

  loadingImg();

  console.log(name, email, message);

  // async function postName(e) {
  //   e.preventDefault();
  //   try {
  //     await axios.post("http://localhost:3001/", {
  //       name,
  //       email,
  //       message,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // document.getElementById("contact-me").addEventListener("submit", (event) => {
  // console.log(document.getElementById("exampleForm.ControlInput2").value);
  // });

  return (
    <>
      {/* <div id="hire-bg" className="hire-bg"> */}
      {/* <div className="left-side"> */}
      {/* <form
        action="https://public.herotofu.com/v1/b3944700-a6ea-11ec-975b-f9cc2b0e6900"
        method="post"
      >
        <div>
          <label for="name">Your Name</label>
          <input name="Name" id="name" type="text" required />
        </div>
        <div>
          <label for="email">Your Email</label>
          <input name="Email" id="email" type="email" required />
        </div>
        <div>
          <input type="submit" value="Download CTA" />
        </div>
      </form> */}

      <Form
        id="contact-me"
        className="contact-form"
        action="https://public.herotofu.com/v1/b3944700-a6ea-11ec-975b-f9cc2b0e6900"
        method="post"
        // data-netlify="true"
      >
        <Form.Group
          id="name"
          className="mb-3"
          controlId="exampleForm.ControlInput2"
          onChange={(e) => setName(e.target.value)}
        >
          <Form.Label>Name</Form.Label>
          <Form.Control name="name" type="name" />
        </Form.Group>
        <Form.Group
          id="email"
          // name="email"
          className="mb-3"
          controlId="exampleForm.ControlInput1"
          onChange={(e) => setEmail(e.target.value)}
        >
          <Form.Label>Email</Form.Label>
          <Form.Control name="email" type="email" />
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
          id="message"
          onChange={(e) => setMessage(e.target.value)}
        >
          <Form.Label>Message</Form.Label>
          <Form.Control name="text" as="textarea" rows={3} />
        </Form.Group>
        <Button type="submit" id="submitbtn">
          Submit
        </Button>
      </Form>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Contact;
