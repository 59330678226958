import thumb from "./Favorites/thumbnail.png";
import thumb2 from "./Favorites/thumb1.png";
import thumb3 from "./Favorites/thumb22.png";
import thumb4 from "./Favorites/thimb4.png";
import thumb5 from "./Favorites/thimb5.png";

import { useState, useEffect } from "react";

// const [hireReady, sethireReady] = useState([]);

const Work = (props) => {
  // const [count, setCount] = useState(0);
  const pics = [thumb5, thumb3, thumb, thumb2, thumb4];

  useEffect(() => {
    var div = document.getElementById("bg");
    div.style.backgroundImage = `url(${pics[props.count]})`;
  }, [props.count]);

  function changeBGRight() {
    if (props.count > pics.length - 2) {
      props.setCount(0);
    } else {
      props.setCount(props.count + 1);
    }
    console.log(props.count);
  }

  function changeBGLeft() {
    if (props.count <= 0) {
      props.setCount(4);
    } else {
      props.setCount(props.count - 1);
    }
    console.log(props.count);
  }

  console.log(props.count);

  return (
    <>
      <div
        id="bg"
        className="buttons work-showcase"
        style={{ backgroundImage: `url(${thumb})` }}
      >
        <span onClick={() => changeBGLeft()} className="arrow left"></span>
        <span onClick={() => changeBGRight()} className="arrow right"></span>
      </div>
    </>
  );
};

export default Work;
