import './App.css';
import Box from "./Components/Box";

function App() {
  return (
    <>
    <Box />
    </>
  );
}

export default App;
