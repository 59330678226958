import bulb from "./Favorites/bulb.svg";
import hb from "./Favorites/hb.svg";
import Button from "react-bootstrap/Button";


const Services = (props) => {
  return (
    <>
      {/* <p className="header-tridery">Helping bring your ideas <img className="bulb" src={bulb} alt="" />    to life  <img className="hb" src={hb} alt="" /></p> */}
      <div className="services-holder">
        <div className="services">
          <div className="text">
            <span className="service">Frontend</span>
            <br></br>
            <span className="service">Design</span>
          </div>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              className="bi bi-code"
              viewBox="0 0 16 16"
            >
              <path d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8l3.147-3.146zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8l-3.147-3.146z" />
            </svg>
          </div>
          <span>
            I can build you a stunning user interface, 100% customizable from top to bottom, with technolgies like
            React, CSS, and Javascript...
          </span>
    
        </div>

        <div className="services">
          <div className="text">
            <span className="service">Backend</span>
            <br></br>
            <span className="service">Development</span>
          </div>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              className="bi bi-hdd-stack"
              viewBox="0 0 16 16"
            >
              <path d="M14 10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z" />
              <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM14 3a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
              <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
            </svg>
          </div>
          <span>
            ...and a robust backend, including server, database and hosting, to support it with technolgies like NodeJS and MongoDB.
          </span>
        </div>
      </div>
      <div className="button">
          <Button className="work-btn" onClick={() => props.workA()}>My Work</Button>
        </div>
    </>
  );
};

export default Services;
