import punk from "../art/ape.png";
import html from "./Favorites/html.png";
import css from "./Favorites/css.png";
import reactlogo from "./Favorites/react.png";
import nodejs from "./Favorites/njs.png";
import js from "./Favorites/js.png";
import mdb from "./Favorites/mdb.png";
import git from "./Favorites/git.png";
import ps from "./Favorites/ps.png";
import thumb from "./Favorites/thumbnail.png";
import thumb1 from "./Favorites/thumb1.png";
import thumb2 from "./Favorites/thumb2.png";
import thumb3 from "./Favorites/thumb3.png";
import thimb4 from "./Favorites/thimb4.png";

import { MdEmail } from "react-icons/md";
import { BsGithub } from "react-icons/bs";
import Services from "./Services";
import Work from "./Work";
import WorkInfo from "./WorkInfo";
import Contact from "./Contact";
import anime from "animejs/lib/anime.es.js";

import { useEffect, useState, useRef } from "react";

const work_info = {
  0: {
    name: "NFT Portfolio",
    description:
      "This is a frontend design for a project that was created for users to have somewhere to display their NFT collection. The backend of this will be a venture into web3.",
  },
  1: {
    name: "Equity Pulse",
    description:
      "his application was developed for fetching company annual and quarterly financial data. The application makes an API call each time the user searches a symbol.",
  },
  2: {
    name: "Equity Pulse Monitor",
    description:
      "This application was developed with the purpose of fetching new data from an ever changing changing database and displaying it for users in realtime.",
    tech: "Built using , Express, React, NodeJS, Socket.io and Firebase",
  },
  3: {
    name: "Restaurant Picker",
    description:
      "This application was developed with the purpose of helping users pick a place to eat. Simply put in your location and let it decide where you should eat.",
  },
};

// var hirebtn = document.getElementById('hire')

// document.getElementById('hire').addEventListener('click', function() {

//   var workInfo = document.getElementById('work-info');

//   // var q1 = document.getElementById("q1");
//   setTimeout(function () {
//     document.getElementById("q1").style.opacity = 100;
//   }, 500);

//   // var q2 = document.getElementById("q2");
//   setTimeout(function () {
//     document.getElementById("q2").style.opacity = 100;
//   }, 700);

//   // var q3 = document.getElementById("q3");
//   setTimeout(function () {
//     document.getElementById("q3").style.opacity = 100;
//   }, 900);

//   // var q4 = document.getElementById("q4");
//   setTimeout(function () {
//     document.getElementById("q4").style.opacity = 100;
//   }, 1200);

//   // var q5 = document.getElementById("q5");
//   setTimeout(function () {
//     document.getElementById("q5").style.opacity = 100;
//   }, 1400);

// });

// var hirebtn = document.getElementById('hire')
// hirebtn.addEventListener('click', function() {

//   var workInfo = document.getElementById('work-info');
//   var contactForm = document.getElementById('contact-me');

//   setTimeout(function () {
//       document.getElementById("name").style.opacity = 100;
//     }, 500);

//     setTimeout(function () {
//       document.getElementById("email").style.opacity = 100;
//     }, 700);

//     setTimeout(function () {
//       document.getElementById("message").style.opacity = 100;
//     }, 900);
//     setTimeout(function () {
//       document.getElementById("submitbtn").style.opacity = 100;
//     }, 1100);

//     var hirebtn = document.getElementById('mail');

//   // var q1 = document.getElementById("q1");
//   setTimeout(function () {
//     document.getElementById("q1").style.opacity = 100;
//   }, 500);

//   // var q2 = document.getElementById("q2");
//   setTimeout(function () {
//     document.getElementById("q2").style.opacity = 100;
//   }, 700);

//   // var q3 = document.getElementById("q3");
//   setTimeout(function () {
//     document.getElementById("q3").style.opacity = 100;
//   }, 900);

//   // var q4 = document.getElementById("q4");
//   setTimeout(function () {
//     document.getElementById("q4").style.opacity = 100;
//   }, 1200);

//   // var q5 = document.getElementById("q5");
//   setTimeout(function () {
//     document.getElementById("q5").style.opacity = 100;
//   }, 1400);

// });
function loadingImg() {
  var loading_logo = document.getElementById("avatar");
  setTimeout(function () {
    loading_logo.style.opacity = 100;
  }, 1200);

  var ele = document.getElementById("header");
  setTimeout(function () {
    ele.style.opacity = 100;
  }, 1700);

  var ele2 = document.getElementById("secondary");
  setTimeout(function () {
    ele2.style.opacity = 100;
  }, 2200);

  var ele3 = document.querySelector("#icon-showcase");
  setTimeout(function () {
    ele3.style.opacity = 100;
  }, 2700);

  var ele4 = document.querySelector("#contact-container");
  setTimeout(function () {
    ele4.style.opacity = 100;
  }, 3200);
}

// function loadingImg() {
//   var loading_logo = document.getElementById("avatar");
//   setTimeout(function () {
//     loading_logo.style.opacity = 100;
//   }, 1500);
// }

const about = document.getElementById("about");
const work = document.getElementById("work");
const hire = document.getElementById("hire");

const Box = () => {
  const [count, setCount] = useState(0);
  const [aboutReady, setaboutReady] = useState(true);
  const [workReady, setworkReady] = useState(false);
  const [hireReady, sethireReady] = useState(false);
  // const [workInfo, setWorkInfo] = useState(false);

  function activator() {
    setaboutReady(true);
    sethireReady(false);
    setworkReady(false);

    document.getElementById("about").style.borderBottom = "solid #282C34";
    document.getElementById("work").style.borderBottom = "none";
    document.getElementById("hire").style.borderBottom = "none";
    document.getElementById("work-info").style.width = "0px";
    document.getElementById("work-info").style.visibility = "hidden";
    document.getElementById("work-info").style.border = "none";
    document.getElementById("work-info").style.backgroundColor = "";

    // setWorkInfo(false);
  }

  function workActivator() {
    setaboutReady(false);
    sethireReady(false);
    setworkReady(true);
    // setWorkInfo(true);

    document.getElementById("about").style.borderBottom = "none";
    document.getElementById("work").style.borderBottom = "solid #282C34";
    document.getElementById("hire").style.borderBottom = "none";
    document.getElementById("work-info").style.visibility = "visible";
    document.getElementById("work-info").style.width = "400px";
    document.getElementById("work-info").style.border = "5px solid #e8e8e8";
    document.getElementById("work-info").style.backgroundColor = "#e8e8e8";
  }

  function hireActivator() {
    setaboutReady(false);
    sethireReady(true);
    setworkReady(false);

    document.getElementById("about").style.borderBottom = "none";
    document.getElementById("work").style.borderBottom = "none";
    document.getElementById("hire").style.borderBottom = "solid #282C34";
    document.getElementById("work-info").style.visibility = "visible";
    document.getElementById("work-info").style.width = "400px";
    document.getElementById("work-info").style.border = "5px solid #e8e8e8";
    document.getElementById("work-info").style.backgroundColor = "#e8e8e8";

    setTimeout(function () {
      document.getElementById("name").style.opacity = 100;
    }, 500);

    setTimeout(function () {
      document.getElementById("email").style.opacity = 100;
    }, 700);

    setTimeout(function () {
      document.getElementById("message").style.opacity = 100;
    }, 900);
    setTimeout(function () {
      document.getElementById("submitbtn").style.opacity = 100;
    }, 1100);

    setTimeout(function () {
      document.getElementById("q1").style.opacity = 100;
    }, 500);

    // var q2 = document.getElementById("q2");
    setTimeout(function () {
      document.getElementById("q2").style.opacity = 100;
    }, 700);

    // var q3 = document.getElementById("q3");
    setTimeout(function () {
      document.getElementById("q3").style.opacity = 100;
    }, 900);

    // var q4 = document.getElementById("q4");
    setTimeout(function () {
      document.getElementById("q4").style.opacity = 100;
    }, 1200);

    // var q5 = document.getElementById("q5");
    setTimeout(function () {
      document.getElementById("q5").style.opacity = 100;
    }, 1400);

    setTimeout(function () {
      document.getElementById("q1").style.opacity = 100;
    }, 500);

    // var q2 = document.getElementById("q2");
    setTimeout(function () {
      document.getElementById("q2").style.opacity = 100;
    }, 700);

    // var q3 = document.getElementById("q3");
    setTimeout(function () {
      document.getElementById("q3").style.opacity = 100;
    }, 900);

    // var q4 = document.getElementById("q4");
    setTimeout(function () {
      document.getElementById("q4").style.opacity = 100;
    }, 1200);

    // var q5 = document.getElementById("q5");
    setTimeout(function () {
      document.getElementById("q5").style.opacity = 100;
    }, 1400);

    // setWorkInfo(false);
  }

  loadingImg();

  console.log(document.getElementById("work-info"));

  return (
    <div>
      <div id="container" className="container">
        <div className="box-one">
          <div
            onLoad={() => loadingImg()}
            id="avatar"
            className="avatar"
            style={{ opacity: 0 }}
          >
            <img src={punk} alt="Skytsunami" />
          </div>

          <span id="header" style={{ opacity: 0 }} className="header">
            Colton Tabb
          </span>

          <span id="secondary" style={{ opacity: 0 }} className="secondary">
            Web Developer
          </span>
          <div
            id="icon-showcase"
            style={{ opacity: 0 }}
            className="icon-showcase"
          >
            <img className="upper" src={html} alt="" />
            <img className="up" src={css} alt="" />
            <img className="upper" src={js} alt="" />
            <img className="up" src={reactlogo} alt="" />
            <img className="down" src={nodejs} alt="" />
            <img className="downer" src={mdb} alt="" />
            <img className="down" src={git} alt="" />
            <img className="downer" src={ps} alt="" />
          </div>
          <div
            id="contact-container"
            style={{ opacity: 0 }}
            className="contact-container"
          >
            {" "}
            <a href="https://github.com/colton-22" className="link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                className="bi bi-github"
                viewBox="0 0 16 16"
              >
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
              </svg>
            </a>
            <svg
              id="mail"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              className="bi bi-envelope"
              viewBox="0 0 16 16"
              onClick={() => hireActivator()}
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
            </svg>{" "}
          </div>
        </div>
        <div id="box-two" className="box-two">
          <div className="navbar">
            <div id="about">
              <span
                onClick={() => activator()}
                className="navbar-item effect-underline"
              >
                Services
              </span>
            </div>
            <div id="work">
              <span
                onClick={() => workActivator()}
                className="navbar-item effect-underline"
              >
                My Work
              </span>
            </div>
            <div id="hire">
              <span
                onClick={() => hireActivator()}
                className="navbar-item effect-underline"
              >
                Hire Me
              </span>
            </div>
          </div>
          {aboutReady && <Services workA={workActivator} />}
          {workReady && <Work count={count} setCount={setCount} />}
          {hireReady && <Contact />}
        </div>
        <WorkInfo count={count} hireReady={hireReady} workReady={workReady} />
      </div>
    </div>
  );
};

export default Box;
